<template>
  <ul class="sidebar-elements">
    <li class="divider">Menu</li>
    <li class="">
      <router-link :to="{ name: 'dashboard' }">
        <span>
          <i class="icon mdi mdi-home"></i>
          <span>Dashboard</span>
        </span>
      </router-link>
    </li>
    <!-- <li class="parent">
                  <a href="#"
                  ><i class="icon mdi mdi-pin-account"></i
                  ><span>Partners</span></a
                  >
                  <ul class="sub-menu">
                    <li class="active"><a href="ui-alerts.html">Add</a></li>
                    <li><a href="ui-buttons.html">List</a></li>
                  </ul>
                </li>
                <li class="parent">
                  <a href="#">
                    <i class="icon mdi mdi-male-female"></i>
                    <span>Users</span>
                  </a>
                  <ul class="sub-menu">
                    <li><a href="charts-flot.html">MF Profiles</a></li>
                    <li><a href="charts-flot.html">Officer Profiles</a></li>
                    <li><a href="charts-sparkline.html">User Signup</a></li>
                    <li><a href="charts-chartjs.html">User Signup</a></li>
                    <li><a href="charts-morris.html">Password Reset</a></li>
                  </ul>
                </li> -->
    <li class="">
      <router-link :to="{ name: 'partners' }">
        <span>
          <i class="icon mdi mdi-case"></i>
          <span>Partners</span>
        </span>
      </router-link>
    </li>
    <li class="">
      <router-link :to="{ name: 'kiosks' }">
        <span>
          <i class="icon mdi mdi-store"></i>
          <span>Kiosks</span>
        </span>
      </router-link>
    </li>
    <li class="">
      <router-link :to="{ name: 'routers' }">
        <span>
          <i class="icon mdi mdi-router"></i>
          <span>Routers</span>
        </span>
      </router-link>
    </li>
    <li class="">
      <router-link :to="{ name: 'docker-images' }">
        <span>
            <i class="icon mdi mdi-cloud"></i>
          <span>Docker Images</span>
        </span>
      </router-link>
    </li>
    <!-- <li class="">
      <router-link :to="{ name: 'locations-cluster' }">
        <span>
          <i class="icon mdi mdi-map"></i>
          <span>Location Cluster</span>
        </span>
      </router-link>
    </li> -->
    <li class="">
      <router-link :to="{ name: 'plans' }">
        <span>
          <i class="icon mdi mdi-money-box"></i>
          <span>Plans</span>
        </span>
      </router-link>
    </li>
    <li class="">
      <router-link :to="{ name: 'apps' }">
        <span>
          <i class="icon mdi mdi-smartphone"></i>
          <span>App Store</span>
        </span>
      </router-link>
    </li>
    <li
      v-if="
        loggedInUser.role === adminRole ||
        loggedInUser.user_type === customerSupportUserType
      "
    >
      <router-link :to="{ name: 'support-tickets' }">
        <span>
          <i class="icon mdi mdi-help"></i>
          <span>Support Tickets</span>
        </span>
      </router-link>
    </li>
    <li class="">
      <router-link :to="{ name: 'my-profile' }">
        <span>
          <i class="icon mdi mdi-account-box"></i>
          <span>My Profile</span>
        </span>
      </router-link>
    </li>
    <!-- <li class="">
      <a href="http://devices.shirikihub.com/" target="_blank">
        <span>
          <i class="icon mdi mdi-cloud-upload"></i>
          <span>Manufacturer Portal</span>
        </span>
      </a>
    </li> -->
    <li v-if="loggedInUser.role === adminRole">
      <router-link :to="{ name: 'users' }">
        <span>
          <i class="icon mdi mdi-male-female"></i>
          <span>System Admins</span>
        </span>
      </router-link>
    </li>
    <li
      v-if="
        loggedInUser.role === adminRole ||
        loggedInUser.role === manufacturerRole
      "
    >
      <router-link :to="{ name: 'manufacturers-list' }">
        <span>
          <i class="icon mdi mdi-settings"></i>
          <span>Manufacturers</span>
        </span>
      </router-link>
    </li>
    <li
      v-if="
        loggedInUser.role === adminRole ||
        loggedInUser.user_type === customerSupportUserType
      "
    >
      <router-link :to="{ name: 'customer-support-agents-list' }">
        <span>
          <i class="icon mdi mdi-headset"></i>
          <span>Customer Support</span>
        </span>
      </router-link>
    </li>
    <li class="">
      <router-link :to="{ name: 'sales-representatives-list' }">
        <span>
          <i class="icon mdi mdi-account"></i>
          <span>Sales Representatives</span>
        </span>
      </router-link>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SideNav",
  data: () => ({
    adminRole: "ared_admin",
    manufacturerRole: "Manufacturer",
    customerSupportUserType: "Customer Support Agent",
    salesRepresentativeUserType: "Sales Representative",
  }),
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
};
</script>

