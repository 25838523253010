import Vue from "vue";
import Router from "vue-router";
import AuthLayout from "@/layout/AuthLayout";
import DashboardLayout from "@/layout/DashboardLayout";
import store from "./store";

const router = new Router({
  linkExactActiveClass: "active",
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "dashboard",
      component: DashboardLayout,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("./views/Dashboard/index"),
          meta: {
            name: "Home"
          }
        },
        {
          path: "/partners",
          name: "partners",
          component: () => import("./views/Partners/index")
        },
        {
          path: "/partners/:id/details",
          name: "partner-details",
          component: () => import("./views/Partners/details")
        },
        {
          path: "/plans",
          name: "plans",
          component: () => import("./views/Plans/index")
        },
        {
          path: "/users",
          name: "users",
          component: () => import("./views/Users/index")
        },
        {
          path: "/manufacturers",
          name: "manufacturers-list",
          component: () => import("./views/Users/manufacturers")
        },
        {
          path: "/customer-support-agents",
          name: "customer-support-agents-list",
          component: () => import("./views/Users/customer_support_agents")
        },
        {
          path: "/sales-representatives",
          name: "sales-representatives-list",
          component: () => import("./views/Users/sales_representatives")
        },
        {
          path: "/users/:id/details",
          name: "user-details",
          component: () => import("./views/Users/details")
        },
        {
          path: "/plans/:id/details",
          name: "plan-details",
          component: () => import("./views/Plans/details")
        },
        {
          path: "/users/profile",
          name: "my-profile",
          component: () => import("./views/Users/profile")
        },
        {
          path: "/kiosks",
          name: "kiosks",
          component: () => import("./views/Kiosks/index")
        },
        {
          path: "/kiosks/:id/details",
          name: "kiosk-details",
          component: () => import("./views/Kiosks/details")
        },
        {
          path: "/routers/",
          name: "routers",
          component: () => import("./views/Routers/index")
        },
        {
          path: "/routers/:id/details",
          name: "router-details",
          component: () => import("./views/Routers/details")
        },
        {
          path: "/docker-images/",
          name: "docker-images",
          component: () => import("./views/DockerImages/index")
        },
        {
          path: "/docker-images/:id/details",
          name: "docker-image-details",
          component: () => import("./views/DockerImages/details")
        },
        {
          path: "/docker-images/:id/edit",
          name: "docker-image-edit",
          component: () => import("./views/DockerImages/edit")
        },
        {
          path: "/apps/",
          name: "apps",
          component: () => import("./views/APK/index")
        },
        {
          path: "/apps/:id/details",
          name: "app-details",
          component: () => import("./views/APK/details")
        },
        {
          path: "/support-tickets/",
          name: "support-tickets",
          component: () => import("./views/Tickets/index")
        },
        {
          path: "/support-tickets/:id/details",
          name: "ticket-details",
          component: () => import("./views/Tickets/details")
        },
        {
          path: "/locations-cluster",
          name: "locations-cluster",
          component: () => import("./views/Locations/index")
        }
      ]
    },
    {
      path: "/",
      redirect: "/login",
      component: AuthLayout,
      children: [
        {
          path: "/login",
          name: "login",
          component: () => import("./views/Auth/Login"),
          meta: {
            name: "Login"
          }
        },
        {
          path: "/2FA",
          name: "2fa",
          component: () => import("./views/Auth/OTP")
        },
        {
          path: "/forgot-password",
          name: "forgot-password",
          component: () => import("./views/Auth/PasswordForgot")
        },
        {
          path: "/password/reset",
          name: "reset-password",
          component: () => import("./views/Auth/PasswordReset")
        }
      ]
    }
  ]
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title =
    toRoute.meta && toRoute.meta.title
      ? toRoute.meta.title
      : "Ared Admin Dashboard";
  if (toRoute.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({
        path: "/login",
        query: {
          redirect: toRoute.fullPath
        }
      });
    } else {
      const user = store.getters.loggedInUser;
      if (user) {
        const allowed_roles = ["ared_admin", "ared_staff"];
        if (!allowed_roles.includes(user.role)) {
          store.commit("clearUserData");
          next({
            path: "/login",
            query: {
              redirect: toRoute.fullPath
            }
          });
        }
      }
      next();
    }
  } else {
    next();
  }
});

export default router;

Vue.use(Router);
